/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect } from 'react'
import { useAuth as useReactFireAuth } from 'reactfire'
import firebaseContext from '../contexts/firebase/context'

const useAuth = () => {
  const { addSdk, activeSDK } = useContext(firebaseContext)
  useEffect(() => {
    if (!activeSDK.includes('auth')) {
      addSdk('auth')
    }
  }, [activeSDK])
  return activeSDK.includes('auth') && useReactFireAuth()
}

export default useAuth
