import React, { useContext, useEffect } from 'react';
import firebaseContext from './context';

const EnsSDK = ({ sdk, children }) => {
  const { addSdk, activeSDK } = useContext(firebaseContext);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      addSdk(sdk);
    }
  }, [sdk]);
  return activeSDK.includes(sdk) && children;
};

const ensureSDK = (sdkNames) => (Component) => (props) =>
  [].concat(sdkNames).reduce((ele, sdk) => (
    <EnsSDK sdk={sdk}>{ele}</EnsSDK>
  ), <Component {...props} />);

export default ensureSDK;
