import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import Script from 'next/script';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Progress } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSigninCheck } from 'reactfire';
import { useInterval, usePrevious, useSearchParam } from 'react-use';

import useAuth from '../hooks/useAuth';
import ensureSDK from 'contexts/firebase/ensureSDK';

const Styled = styled.div`
  button {
    border-radius: 8px;
  }
  .firebaseui-container {
    border-radius: 8px;
  }
  button[data-provider-id='password'] {
    background-color: var(--chakra-colors-gray-800) !important;
  }
  .mdl-button--primary.mdl-button--primary {
    color: var(--chakra-colors-gray-800);
  }
  .mdl-button--raised.mdl-button--colored {
    background: var(--chakra-colors-gray-800);
  }
  .mdl-button--raised.mdl-button--colored:hover {
    background: var(--chakra-colors-gray-700);
  }
  .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: var(--chakra-colors-gray-800);
  }
  .firebaseui-link {
    color: var(--chakra-colors-gray-800);
  }
`;

const TheProgress = () => (
  <Progress size="xs" w="10em" isIndeterminate colorScheme="gray" />
);

const providers = [
  { name: 'email', id: EmailAuthProvider.PROVIDER_ID },
  { name: 'google', id: GoogleAuthProvider.PROVIDER_ID }
];

const StyledFirebaseAuth = ({ auth, defaultProvider, isSignUp }) => {
  const redirectTo = useSearchParam('redirectTo');
  // const redirect = redirectTo && decodeURIComponent(redirectTo)
  // const [loggedIn, setLoggedIn] = useState(false)
  const { data: signInCheckResult } = useSigninCheck();
  const prevSignInCheckResult = usePrevious(signInCheckResult);

  const ref = useRef();
  const router = useRouter();
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: redirectTo || '/editor',
    // We will display Google and Facebook as auth providers.
    signInOptions: (defaultProvider
      ? providers.filter(({ name }) => name === defaultProvider)
      : providers
    ).map(({ id }) => id),
    callbacks: {
      signInSuccess(currentUser) {
        // if (currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime) {
        //   trackUserRegister(currentUser)
        // }
        router.push(redirectTo || '/editor');
      }
    }
  };
  useEffect(() => {
    const uiInstance = window.firebaseui.auth?.AuthUI?.getInstance('[DEFAULT]');

    if (uiInstance) {
      try {
        uiInstance.start(ref.current, uiConfig);
      } catch (e) {
        window.location.reload();
      }
    } else {
      const ui = new window.firebaseui.auth.AuthUI(auth);

      ui.start(ref.current, uiConfig);

      setTimeout(() => {
        if (defaultProvider === 'google') {
          ref.current.querySelector('.firebaseui-idp-google')?.click();
        }
      });
    }
    // auth.onAuthStateChanged(user => {
    //   if (user?.uid) {
    //     // ref.current.innerHTML = ''
    //     setLoggedIn(true)
    //   }
    // })
  }, [defaultProvider]);

  useEffect(() => {
    if (signInCheckResult?.signedIn && !prevSignInCheckResult) {
      router.push(redirectTo || '/editor');
    }
  }, [signInCheckResult]);
  return (
    <>
      <Styled ref={ref} />
      {/* {loggedIn && <TheProgress />} */}
    </>
  );
};

const EnsureAuth = (props) => {
  const [firebase, setFirebase] = useState();
  useEffect(() => {
    Promise.all([
      import('firebase/compat/app'),
      import('firebase/compat/auth')
    ]).then(([{ default: firebase }]) => {
      setFirebase(firebase);
      window.firebase = firebase;
    });
  }, []);
  return firebase ? <StyledFirebaseAuth {...props} /> : <TheProgress />;
};

function LoginUi({ defaultProvider, isSignUp }) {
  const auth = useAuth();
  const [remoteLoaded, setLoaded] = useState();

  useInterval(
    () => {
      setLoaded(!!window.firebaseui?.auth);
    },
    remoteLoaded ? null : 100
  );
  return (
    <>
      <Script
        id="firebaseui"
        src={`/lib/firebase-ui-auth__zh_tw${isSignUp ? '_signup' : ''}.js`}
      />
      <link
        type="text/css"
        rel="stylesheet"
        href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
      />
      {auth && remoteLoaded ? (
        <EnsureAuth
          isSignUp={isSignUp}
          auth={auth}
          defaultProvider={defaultProvider}
        />
      ) : (
        <TheProgress />
      )}
    </>
  );
}

export default ensureSDK(['auth', 'firestore'])(LoginUi);
